@import './utils.scss'; //*** Global Styles ***//

// checkbox style
.pure-material-checkbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 11px;
  }
  & > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -8px;
    top: -5px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: none;
    outline: none;
    opacity: 0.001;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;
  }
  & > span {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    &:before {
      content: "";
      display: inline-block;
      box-sizing: border-box;
      margin: 3px 20px 3px 0px;
      border: solid 1px; /* Safari */
      border-color: $color-blue-25;
      border-radius: 0;
      width: 24px;
      height: 24px;
      min-width: 24px;
      vertical-align: top;
      transition: border-color 0.2s, background-color 0.2s;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        margin-right: 10px;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 1px;
      width: 10px;
      height: 5px;
      border: solid 2px transparent;
      border-right: none;
      border-top: none;
      transform: translate(6px, -60%) rotate(-45deg);
    }
  }
  & > input:checked,
  & > input:indeterminate {
    background-color: #fff;
  }
  & > input:checked + span:before,
  & > input:indeterminate + span:before {
    border-color: #fff;
    background-color: #fff;
  }
  & > input:checked + span:after,
  & > input:indeterminate + span:after {
    border-color: $color-blue;
  }
  & > input:indeterminate + span:after {
    border-left: none;
    transform: translate(4px, 3px);
  }
  &:hover > input {
    opacity: 0.04;
  }
  & > input:focus {
    opacity: 0.12;
  }
  &:hover > input:focus {
    opacity: 0.16;
  }
  & > input:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
  }
  & > input:active + span:before {
    border-color: #fff;
  }
  & > input:checked:active + span:before {
    border-color: transparent;
    background-color: transparent;
  }
  & > input:disabled {
    opacity: 0;
  }
  & > input:disabled + span {
    color: rgba(39,37,96, 0.38);
    cursor: initial;
  }
  & > input:disabled + span:before {
    border-color: currentColor;
  }
  & > input:checked:disabled + span:before,
  & > input:indeterminate:disabled + span:before {
    border-color: transparent;
    background-color: currentColor;
  }
}
